<template>
  <div class="bg-white p-4 h-full text-black">
    <h2 class="font-bold fs-24 mb-4">{{ $t("Danh sách Danh mục Dịch vụ") }}</h2>

    <div class="flex flex-col md:flex-row gap-2 justify-between items-center">
      <div
        class="flex gap-2 items-center"
        style="max-width: 400px; width: 100%"
      >
        <el-input
          class="cs-input"
          placeholder="Tìm kiếm theo tên,..."
          v-model="searchValue"
          clearable
          @input="handleDebounce"
        >
          <el-button slot="prepend" icon="el-icon-search"></el-button>
        </el-input>
      </div>

      <el-button
        @click="handleOpenModalCreate"
        icon="el-icon-plus"
        type="primary"
        >{{ $t("Thêm mới") }}</el-button
      >
    </div>

    <div class="mt-4">
      <el-table
        :data="serviceCategories"
        style="width: 100%"
        row-class-name="text-black text-sm"
        header-cell-class-name="text-black text-base font-semibold"
        class="cursor-pointer"
        @row-click="goToViewDetail"
      >
        <el-table-column
          width="400"
          prop="name"
          label="Tên Danh mục Dịch vụ"
        ></el-table-column>
        <el-table-column
          width="400"
          prop="description"
          label="Mô tả"
        ></el-table-column>
        <!-- <el-table-column
          :formatter="
            (row) => {
              return row.created_by && row.created_by.name;
            }
          "
          label="Người tạo"
        ></el-table-column>-->
        <el-table-column
          :formatter="handleFormatDate"
          label="Ngày tạo"
          align="right"
        ></el-table-column>
      </el-table>
      <div class="flex justify-content-end mt-3">
        <AppPagination
          :paginationProps="pagination"
          @onChangePagination="getListServiceCategory"
        />
      </div>
    </div>
    <ModalCreateServiceCategory
      ref="ModalCreateServiceCategory"
      @onRefresh="getListServiceCategory"
    />
  </div>
</template>

<script>
import { AppPagination } from '../../components/Common'
import appUtils from '../../utils/appUtils'
import ModalCreateServiceCategory from '../../components/ServiceManage/ModalCreateServiceCategory.vue'
import { debounce } from 'lodash'
import moment from 'moment'

export default {
  name: 'ServiceManagement',
  components: { AppPagination, ModalCreateServiceCategory },
  data () {
    return {
      pagination: {
        currentPage: 1,
        pageSize: 15,
        totalItems: 0
      },
      searchValue: '',
      serviceCategories: [],
      appUtils
    }
  },
  created () {
    this.getListServiceCategory()
  },
  methods: {
    async getListServiceCategory (data) {
      if (!this.$globalOrg?.id) return
      try {
        const params = {
          org_id: this.$globalOrg?.id,
          page_num: data?.currentPage || this.pagination.currentPage,
          page_size: data?.pageSize || this.pagination.pageSize,
          sort_by: 'created_at',
          order: 'desc'
        }
        if (this.searchValue) {
          params.keyword = this.searchValue
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getListServiceCategory(params)

        const responseData = response.data || {}
        this.serviceCategories = responseData?.data || []
        this.pagination = {
          currentPage:
            responseData?.page?.page_num || this.pagination.currentPage,
          pageSize: responseData?.page?.page_size || this.pagination.pageSize,
          totalItems: responseData?.page?.total || 0
        }
        console.log(response)
      } catch (error) {
        console.log(error)
      }
    },
    goToViewDetail (row) {
      //   console.log(row)
      this.$refs.ModalCreateServiceCategory.handleOpen(true, row)
    },
    handleOpenModalCreate () {
      this.$refs.ModalCreateServiceCategory.handleOpen()
    },
    handleDebounce: debounce(function () {
      this.getListServiceCategory()
    }, 500),
    handleFormatDate (row) {
      const createdDate = moment(row.created_at).subtract(7, 'hours')

      return appUtils.formatDateTime(createdDate)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .cs-input {
    .el-input__inner {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border: 1px solid #dcdfe6 !important;
      background-color: white !important;
    }

    .el-input-group__append {
      background-color: white !important;
    }
  }

  .cs-disabled {
    .el-input__inner {
      background-color: rgba(228, 228, 228, 0.823) !important;
      color: #373737 !important;
    }

    .el-input-group__append {
      background-color: rgba(228, 228, 228, 0.823) !important;
    }
  }
}
</style>