import { render, staticRenderFns } from "./ModalCreateServiceCategory.vue?vue&type=template&id=83b27630&scoped=true"
import script from "./ModalCreateServiceCategory.vue?vue&type=script&lang=js"
export * from "./ModalCreateServiceCategory.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83b27630",
  null
  
)

export default component.exports