<template>
  <div>
    <el-dialog
      :title="
        $t(isUpdate ? 'Cập nhật Danh mục Dịch vụ' : 'Tạo Danh mục Dịch vụ')
      "
      :visible.sync="isOpen"
      width="60%"
      center
    >
      <div v-loading="isLoading">
        <div>
          <label class="required">{{ $t("Tên Danh mục Dịch vụ") }}</label>
          <el-input placeholder="Nhập tên danh mục" v-model="name"></el-input>
        </div>
        <div class="mt-4">
          <label class="required">{{ $t("Nhóm dịch vụ") }}</label>
          <el-select
            class="w-full"
            clearable
            filterable
            v-model="service_group_category_id"
            placeholder="Chọn nhóm dịch vụ"
          >
            <el-option
              v-for="(item, index) in SERVICE_GROUP"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="mt-4">
          <label>{{ $t("Mô tả") }}</label>

          <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 8 }"
            placeholder="Nhập mô tả"
            v-model="description"
          ></el-input>
        </div>
        <div class="flex gap-2 justify-end mt-3">
          <el-button :disabled="isLoading" @click="handleClose">
            {{ $t("Huỷ") }}
          </el-button>
          <el-button
            :disabled="isLoading"
            type="primary"
            @click="handleSubmit"
            >{{ $t(isUpdate ? "Cập nhật" : "Tạo") }}</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { SERVICE_GROUP } from '../../utils/constants'
export default {
  name: 'ModalCreateServiceCategory',

  data () {
    return {
      isOpen: false,
      description: '',
      name: '',
      service_group_category_id: '',
      isUpdate: false,
      serviceCateDetail: {},
      isLoading: false,
      SERVICE_GROUP
    }
  },

  methods: {
    async handleOpen (update, data = {}) {
      console.log('🚀 ~ handleOpen ~ update:', update)
      this.isOpen = true

      if (update) {
        await this.getDetailServiceCategoryById(data.id)
        this.isUpdate = update
        // this.serviceCateDetail = data
        this.name = this.serviceCateDetail.name
        this.description = this.serviceCateDetail.description
        this.service_group_category_id =
          this.serviceCateDetail.service_group_category_id || ''
      } else {
        this.isUpdate = false
        this.name = ''
        this.description = ''
      }
    },
    async getDetailServiceCategoryById (id) {
      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getDetailServiceCategoryById(id)
        if (response.status === 200) {
          this.serviceCateDetail = response.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleClose () {
      this.name = ''
      this.description = ''
      this.isUpdate = false
      this.serviceCateDetail = {}
      this.isOpen = false
      this.service_group_category_id = ''
    },
    handleSubmit () {
      if (!this.name) {
        this.$message({
          message: this.$t('Vui lòng nhập tên danh mục dịch vụ'),
          type: 'warning'
        })

        return
      }

      if (this.isUpdate) {
        this.handleUpdate()
      } else {
        this.handleCreate()
      }
    },

    async handleCreate () {
      if (!this.$globalOrg?.id) return

      try {
        const isConfirm = await this.$confirm(
          this.$t('Bạn có chắc chắn muốn tạo mới danh mục dịch vụ không?'),
          {
            cancelButtonText: this.$t('lbl_cancel'),
            confirmButtonText: this.$t('lbl_confirm')
          }
        ).catch(() => {})

        if (!isConfirm) return

        const params = {
          name: this.name,
          description: this.description,
          org_id: this.$globalOrg?.id,
          service_group_category_id: this.service_group_category_id
        }

        this.isLoading = true
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .postCreateServiceCategory(params)

        if (response.status === 200) {
          this.$message({
            message: this.$t('Tạo danh mục dịch vụ thành công'),
            type: 'success'
          })
        }

        this.handleClose()
        this.$emit('onRefresh')
      } catch (error) {
        console.log(error)
        this.$message({
          message: this.$t('Có lỗi xảy ra khi tạo danh mục dịch vụ'),
          type: 'error'
        })
      } finally {
        this.isLoading = false
      }
    },
    async handleUpdate () {
      if (!this.serviceCateDetail?.id) return

      try {
        const isConfirm = await this.$confirm(
          this.$t('Bạn có chắc chắn muốn cập nhật danh mục dịch vụ không?'),
          {
            cancelButtonText: this.$t('lbl_cancel'),
            confirmButtonText: this.$t('lbl_confirm')
          }
        ).catch(() => {})

        if (!isConfirm) return

        const params = {
          name: this.name,
          description: this.description,
          service_group_category_id: this.service_group_category_id
        }
        this.isLoading = true

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .putUpdateServiceCategory(this.serviceCateDetail.id, params)

        if (response.status === 200) {
          this.$message({
            message: this.$t('Cập nhật danh mục dịch vụ thành công'),
            type: 'success'
          })
        }

        this.handleClose()
        this.$emit('onRefresh')
      } catch (error) {
        console.log(error)
        this.$message({
          message: this.$t('Có lỗi xảy ra khi cập nhật danh mục dịch vụ'),
          type: 'error'
        })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>